.Header button {
  background: white;
  border: none;
  font-size: 30px;
  color: darkgray;
  margin: 10px;
  cursor: pointer;
}

.Header h1 {
  font-size: 30px;
}

.Header .Rules {
  margin-right: 40px;
}

.Header .Settings {
  margin-left: 40px;
}
