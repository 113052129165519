.Keyboard {
  display: grid;
  place-items: center;
  margin: 10px;
}

.Key {
  width: auto;
  height: 45px;
  min-width: 25px;
  margin: 2px;
  border: none;
  background-color: lightgray;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
}

.right-key {
  background: #adff2f;
  color: #fff;
}

.wrong-key {
  background: #808080;
  color: #fff;
}

.close-key {
  background: #ffd900;
  color: #fff;
}
