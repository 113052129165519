.Game {
  display: grid;
  place-items: center;
  margin-bottom: 20px;
}

.Header {
  display: flex;
}

.Mainboard {
  display: grid;
  place-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.Panel {
  background: #fff;
  border: 2px solid #999;
  float: left;
  font-size: 33px;
  font-weight: bold;
  height: 45px;
  width: 45px;
  margin-right: 2px;
  margin-top: 2px;
  padding: 0;
  text-align: center;
}

.right-panel {
  background: #adff2f;
  border: 2px solid greenyellow;
  color: #fff;
}

.wrong-panel {
  background: #808080;
  border: 2px solid gray;
  color: #fff;
}

.close-panel {
  background: #ffd900;
  border: 2px solid gold;
  color: #fff;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}
